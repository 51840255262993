import React from 'react';
import Card from 'react-bootstrap/Card';
import PropTypes from 'prop-types';

BlogCard.propTypes = {
    imageSrc: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    link: PropTypes.string.isRequired
};

export function BlogCard({imageSrc, title, description="", link}) {
    return (
    <Card className="blogpost-card h-100">
        <Card.Img variant="top" src={ imageSrc } className="blogpost-card-img"/>
        <Card.Body>
            <h2 className="blogcard-text">{ title }</h2>
            <Card.Text>
            { description }
            </Card.Text>
            → <a className="blog-card-link" href={link}>Read More</a>
        </Card.Body>
    </Card>);
}