import React from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import welbi from '../../tmpImages/welbi.png';
import { Helmet } from "react-helmet";
import amy_photo from "../../tmpImages/amy_photo.jpeg"


export function Welbi() {
    return (
        <div className="whitedrop-page">
            <Helmet>
                <title>Vire: Blog - Welbi</title>
            </Helmet>
            <meta property="og:image" content={ welbi } />
            <meta property="og:image:width" content="180" />
            <meta property="og:image:height" content="110" />
            <div>
                <div className="blogpost-container">
                    <div className="blogpost-header-container">
                        <h2 className="blogpost-header">Welbi</h2>
                        <h4 className="blogpost-subheader">Data visualization and recommendations to tackle the social isolation epidemic.</h4>
                        <div className="author-header">
                            <div>
                                <Image className="author-avatar" src={ amy_photo }/>
                            </div>
                            <div className="blogpost-date-author">
                                <p className="blogpost-date-author-item">Amy Slawson</p>
                                <p className="blogpost-date-author-item">November 16, 2021 · 7 min read</p>
                            </div>
                        </div>
                    </div>
     
                    <Image variant="top" src={ welbi } className="blogpost-header-img"/>
                    <p><em>Social isolation is as harmful as smoking 15 cigarettes a day, yet more than half of seniors living in residential care suffer from social isolation. This health epidemic impacts not only the residents but loved ones and society as well. I learned how Welbi is tackling this problem head-on from Elizabeth Audette-Bourdeau, CEO and founder.</em></p>
                    <p><em>This article has been edited and condensed for clarity.</em></p>
                    <p className="breakpoint-dots">• • •</p>
                    <div className="interview-question">
                        <p><b className="interview-question-q">What inspired you to start Welbi?</b></p>
                        <p>When my grandfather fell ill and started needing more support, my dad would drive two hours every day to check on him, and it became heavy on my family. My family decided to put him into a senior living community expecting either his health status to persist or possibly get better as he engaged with a new community. Sadly, it did the opposite, and he passed away a few months later.</p>
                        <Image src="https://storage.googleapis.com/vv-images/welbi_image5.png" className="blogpost-img"/>
                        <p className="img-caption">Elizabeth with her grandfather.</p>
                        <p>For me, this was an awakening moment. Doing some research, I discovered 54% of the seniors living in residential care are suffering from social isolation (before the Covid-19 pandemic, <a className="blog-link" target="_blank" rel="noopener noreferrer" href="https://medium.com/r?url=https%3A%2F%2Fwww.welbi.co%2Fblog%2Fthe-dangers-of-senior-loneliness-and-isolation">source</a>). Social isolation is as harmful as smoking 15 cigarettes a day, and it increases your chances of suffering from dementia by 64%. I knew we had to find a solution. This could not happen with any of my other grandparents and families like mine.</p>
                    </div>
                    <div className="interview-question">
                        <p><b className="interview-question-q">How did you get started with Welbi?</b></p>
                        <p>In the beginning, I thought that the best way to tackle the adverse health effects of social isolation would be an app that would integrate with wearables like Fitbit or Apple watch. It would establish and manage data on a person's habits and then alert caregivers to any changes. In my family's example, it could have warned us when my grandfather was not doing well, and we could have driven up to visit.</p>
                        <Row>
                            <Col xs={12} sm={12} md={6} lg={6}>
                                <Image src = "https://storage.googleapis.com/vv-images/welbi_image2.png" className="blogpost-img"/>
                                <p className="img-caption">The Welbi team chatting with residential community staff members about their challenges. (Copyright: CityNews Ottawa)</p>
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={6}>
                                <p>After deploying the app, we recognized that this wasn't the right solution. The problem was not the caregivers knowing what was going on with their loved ones. The problem was not even the lack of this knowledge within the senior living communities. The more immediate issue was the shortage of time that the staff had to get to know the residents. They are consistently overwhelmed. We decided to pivot to provide the team with tools to alleviate all their administrative tasks. If we could free them up, they could spend more time with the residents, get to know them, and identify their individual habits and changes. We've partnered with Canada's largest retirement home group and the second largest in North America to develop and test our platform.</p>
                            </Col>
                        </Row>
                    </div>
                    <div className="interview-question">
                        <p><b className="interview-question-q">What is Welbi's mission?</b></p>
                        <p>Welbi's mission is to help senior living communities deliver exceptional and unique life experiences. We aim to provide older adults with the experiences they deserve and ensure that they're not socially isolated.</p>
                    </div>
                    <h4 className="blog-quote">"Social isolation is as bad as smoking 15 cigarettes a day and it increases your chances of suffering from dementia by 64%."</h4>
                    <div className="interview-question">
                        <p><b className="interview-question-q">What is the impact of social isolation on society at large?</b></p>
                        <p>In addition to affecting an individual's health, this problem has a high impact on our health systems. We need to take care of older adults, and being socially isolated increases their chances of suffering from dementia and other diseases. Especially now with Covid-19, every hospital bed is occupied in treating the virus, and there is a significant backlog for taking care of older adults. Early results from active research suggest that the effects of social isolation due to Covid-19 have led to more death and disease than the virus itself for older adults.</p>
                    </div>
                    <div className="interview-question">
                        <p><b className="interview-question-q">What hurdles does the recreation team experience in providing its residents the best care?</b></p>
                        <p>Whenever a loved one moves into your senior living community, the staff will ask them a few questions, which will often be on a piece of paper that gets put on a shelf or in a binder. The team rarely references the data because they are too overwhelmed. Even if they did refer to this data, with over 400 residents, no human could even draw relationships between one and the other.</p>
                    </div>
                    <h4 className="blog-quote">"In the end, the staff did not sign up to do administrative tasks. They want to spend time with the residents, and we help make that possible."</h4>
                    <div className="interview-question">
                        <p><b className="interview-question-q">How does Welbi support these needs?</b></p>
                        <p>First, Welbi digitizes this experience, helping staff members better understand residents through an assessment and build a resident profile with their hobbies, where they come from, where they went to school, etc. From there, we aggregate all the data and can start making activity recommendations or highlighting similarities among residents. An example could be "you have 55% of your community that loves hockey," and then the product will guide the recreation team to organize a game viewing night. Our platform also helps during staff member churn, ensuring the knowledge on residents is not siloed and lost.</p>
                        <p>Welbi gets to know every resident, helps activate all the data, and enables recreation teams to build a program that reflects their community and maximizes resident engagement. We also evaluate the impact of that program, surfacing participation rates, the level of engagement, who has been disengaged, and what their interests are. We save up to 25 hours per month of administrative tasks to the staff. That is 25 hours that can now be spent with the residents instead of behind their desks.</p>
                    </div>
                    <Row className="blogpost-img-row">
                        <Col xs={12} sm={12} md={6} lg={6}>
                            <Image src = "https://storage.googleapis.com/vv-images/welbi_image4.png" className="blogpost-img"/>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6}>
                            <Image src = "https://storage.googleapis.com/vv-images/welbi_image7.png" className="blogpost-img"/>
                        </Col>
                        <p className="img-caption large-img-caption">The Welbi platform enables recreation teams to learn about their residents and design programs optimized for engagement and connections.</p>
                    </Row>
                    <div className="interview-question">
                        <p><b className="interview-question-q">How did the pandemic impact the product?</b></p>
                        <p>We used to support the staff in organizing many group events, but the engagement had to be on a more one-on-one basis with the pandemic. We had all the data on individual residents, so we hustled to build a one-on-one component.</p>
                        <p>We also developed a contact tracing program. We had access to all the data regarding human interactions during recreation programming. If a staff member came to one's room to do 30 minutes of puzzles, we knew a possible point of contact was there. With one click in Welbi, you can now trace back what interactions happened during a period.</p>
                    </div>
                    <div className="interview-question">
                        <p><b className="interview-question-q">What are some signs of success?</b></p>
                        <p>First of all, saving 25 hours a month is huge because this is almost a whole week of work for the staff. Recreation teams also feel like their programs are improving and increasing engagement. On a large scale, we are already helping tens of thousands of seniors all over Canada. We are penetrating the US now with some communities onboarding these upcoming months.</p>
                        <p>We are still evaluating the full impact on the health and wellness of the residents as this is a long-term insight. But there have been studies showing that someone who participates in a more personalized program adapted to their needs will stay in their communities for 2.7 years longer. This is what we are working towards.</p>
                    </div>
                    <h4 className="blog-quote">"My team inspires me every day. Their dedication motivates me to push harder."</h4>
                    <div className="interview-question">
                        <p><b className="interview-question-q">How have you been able to stay true to your mission? Has it ever been challenged?</b></p>
                        <p>I think we've always stayed true to our mission. There is this new generation coming in with higher expectations for personalized experiences. Today, we have Netflix, Amazon, and other companies making recommendations targeted towards our interest, so why are we not doing this in senior living communities? It's even more important here for the residents to be engaged.</p>
                        <p>Our early pivot, from wearable technology to staff tooling, is a good example of staying true to the mission. We recognized that the earlier approach would not have the impact we aimed for, so we sought better ways to address the problem.</p>
                    </div>
                    <div className="interview-question">
                        <p><b className="interview-question-q">How have you struck a balance between profit and your mission?</b></p>
                        <p>Having that mission is highly motivating for employees, as well as rewarding. We are getting messages almost every week from grateful users. They've shared stories about how they managed to foster more engagement and connection with the residents. We have even had new users cry, asking, "where have you guys been all this time?" In the end, the staff did not sign up to do administrative tasks. They want to spend time with the residents, and we help make that possible.</p>
                    </div>
                    <div className="interview-question">
                        <p><b className="interview-question-q">What companies inspire you?</b></p>
                        <Row>
                            <Col xs={12} sm={12} md={6} lg={6}>
                                <Image src = "https://storage.googleapis.com/vv-images/welbi_image3.png" className="blogpost-img"/>
                                <p className="img-caption">Elizabeth and the Welbi team hard at work.</p>
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={6}>
                                <p>When I was young, I was a huge fan of Nike, mainly due to its branding and marketing. I read the book Shoe Dog, which helped me understand the grind behind having your own company and starting from scratch. The co-founder Phil Knight began selling shoes out of his car!</p>
                            </Col>
                        </Row>
                        <p>Today, Welbi inspires me. My team inspires me every day. Their dedication motivates me to push harder.</p>
                    </div>
                    <div className="interview-question">
                        <p><b className="interview-question-q">How can people get involved and support your effort?</b></p>
                        <p>If anyone knows of any senior living communities interested in Welbi, ask them to visit our <a className="blog-link" target="_blank" rel="noopener noreferrer" href="https://www.welbi.co/">website</a> or contact us. We are also <a className="blog-link" target="_blank" rel="noopener noreferrer" href="https://www.welbi.co/careers">recruiting for remote positions and positions in Ottawa</a>.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}
