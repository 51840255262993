import React from "react";
import Image from 'react-bootstrap/Image';
import { Helmet } from "react-helmet";
import vv_1 from '../../tmpImages/vv_1.png';
import amy_photo from "../../tmpImages/amy_photo.jpeg"


export function Beginning() {
    return (
        <div className="whitedrop-page">
            <Helmet>
                <title>Vire: Blog - Beginning</title>
            </Helmet>
            <div>
                <div className="blogpost-container">
                    <div className="blogpost-header-container">
                        <h2 className="blogpost-header">The Beginning of Virtuous Ventures</h2>
                        <div className="author-header">
                            <div>
                                <Image className="author-avatar" src={ amy_photo }/>
                            </div>
                            <div className="blogpost-date-author">
                                <p className="blogpost-date-author-item">Amy Slawson</p>
                                <p className="blogpost-date-author-item">Jul 21, 2020 · 2 min read</p>
                            </div>
                        </div>
                    </div>
                    <Image variant="top" src={ vv_1 } className="blogpost-header-img"/>
                    <div className="interview-question">
                        <p>While recently looking for my next career play, I put a strong emphasis on the company mission. Motivated by the chorus of Hamilton quotes repeating in my head, one stood out: “If you stand for nothing Burr, what will you fall for?” Consequently, if I planned to spend 40+ hours a week in a single environment, I wanted to ensure that it was spent creating positive social and environmental impact. In my extensive search for the perfect fit, I scoured endlessly throughout my network, LinkedIn, top startup lists, venture capital profiles, ect. An internal debate evolved between the pros and cons of non-profit vs for-profit organizations. Additionally, I developed a special intrigue in the advantages and challenges for companies optimizing for people, planet, and profit (the three Ps).</p>
                        <p>I believe I found the perfect fit for me: cLabs working on Celo, a platform to enhance global financial inclusion. In the search process, however, I discovered a lot of companies doing amazing things that filled me with inspiration. This led me to start Virtuous Ventures in hopes of passing on that inspiration.</p>
                        <p>Virtuous Ventures showcases early stage companies that develop products for positive impact. It explores how they leverage technology for good and the unique challenges at the intersection of social, environmental, and economic developments.</p>
                        <p>I can’t wait to highlight the incredible work of these startups and to hear from you. Let me know what you think and if there’s a company that inspires you!</p>
                    </div>
                </div>
            </div>
        </div>
    );
}
