import React from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import emergingimpact from '../../tmpImages/ei.png';
import { Helmet } from "react-helmet";
import amy_photo from "../../tmpImages/amy_photo.jpeg"

export function EmergingImpact() {
    return (
        <div className="whitedrop-page">
            <Helmet>
                <title>Vire: Blog - Emerging Impact</title>
            </Helmet>
            <div>
                <div className="blogpost-container">
                    <div className="blogpost-header-container">
                        <h2 className="blogpost-header">Emerging Impact</h2>
                        <h4 className="blogpost-subheader">Providing the technical expertise for non-profits to address the most pressing needs of society and our planet.</h4>
                        <div className="author-header">
                            <div>
                                <Image className="author-avatar" src={ amy_photo }/>
                            </div>
                            <div className="blogpost-date-author">
                                <p className="blogpost-date-author-item">Amy Slawson</p>
                                <p className="blogpost-date-author-item">Sep 13, 2020 · 9 min read</p>
                            </div>
                        </div>
                    </div>
                    <Image src={ emergingimpact } className="blogpost-header-img"/>

                    <p><em>How do we address the most pressing needs of society and our planet with the latest technologies? I spoke with Robert Greenfield, CEO of Emerging Impact about how they provide the technical expertise to non-profits to do just that! Additionally, Robert shared his goals in driving the #CryptoForBlackLives campaign and his hopes for the future of diversity within the blockchain community.</em></p>
                    <p><em>This article has been edited and condensed for clarity.</em></p>
                    <p className="breakpoint-dots">• • •</p>
                    <div className="interview-question">
                        <p><b className="interview-question-q">What does Emerging Impact do?</b></p>
                        <p>Emerging Impact is the technical partner for NGOs and government entities. It empowers these organizations to modernize financial services for the most disenfranchised people in the world.</p>
                        <p>The two main things that we are focused on now are modernizing cash assistance programs, which effectively becomes synonymous with new age humanitarian aid, and environmental finance. Additionally, we have a pro bono focus on education with a focus around diversity and inclusion; such as our partnership with Black Girls Code, getting people of color into the blockchain space and finding opportunities for them, and running the #CryptoForBlackLives campaign. All our co-founders are black and we have lived this experience, so we find it really important.</p>
                    </div>
                    <div className="interview-question">
                        <p><b className="interview-question-q">Can you provide some examples of non-profits you’ve worked with?</b></p>
                        <p>We have been doing some research and design work for a company called <a className="blog-link" href="https://www.blocpower.io/" target="_blank" rel="noopener noreferrer">BlocPower</a>, which partners with cities and retrofits urban buildings typically located in low-income neighborhoods. These buildings are old, and they process energy inefficiently. Beyond polluting the Earth, this can lead to negative health outcomes for the residents — such as asthma.</p>
                        <p>Together, we are working to understand how blockchain can be a part of BlocPower’s operating model. Originally, the focus was on carbon offsets. For example, we could measure the carbon savings in a verifiable, transparent manner to sell to a market and provide carbon rebates to the people who live near the projects. But we have also found that this template for the lifecycle of a blockchain-based carbon offset could be applied to other environmental commodities. We envision a future where there is cryptocurrency-like exchange for such environmental commodities, increasing transparency of where they are sourced and how companies and consumers use them.</p>
                    </div>
                    <h4 className="blog-quote">“In this age of increased conscious capitalism, commercial businesses are starting to realize they need to make social impact less supplemental.”</h4>
                    <div className="interview-question">
                        <p><b className="interview-question-q">Is the company only focused on solutions that leverage blockchain for its partners?</b></p>
                        <p>The original hope was to open it up to things like VR and machine learning. For at least the first year we will probably focus on blockchain, mainly due to capacity constraints in supporting all these new technologies. I believe we really need an expert for each of these technologies to provide a quality consulting service that leverages them. Right now, the focus is on blockchain since it is where I have the greatest expertise. As we scale, the utopic intent is to bridge the gap for these partners using whatever technology meets the need.</p>
                    </div>
                    <div className="interview-question">
                        <p><b className="interview-question-q">How did you come to start Emerging Impact?</b></p>
                        <p>Over the past four years, I’ve been leading ConsenSys Social Impact, the social sector practice of <a className="blog-link" href="https://consensys.net/" target="_blank" rel="noopener noreferrer">ConsenSys</a>. The first version of all of this was the Blockchain for Social Impact Coalition (BSIC), which started when the application development use case for blockchain had just begun.</p>
                        <Row>
                            <Col xs={12} sm={12} md={6} lg={6}>
                                <p>For the commercial sector, everybody was focused on supply chain transparency. The social sector, which is about a year and a half behind, was just doing 101 sessions as to what is blockchain and how can you use it? We started the BSIC to create that community, providing free webinars, conferences, and hackathons around how blockchain can be applied.</p>
                                <p>From that, we started to see that the social sector seemed ready to create a lot of these solutions and test them out, but there needed to be consultancy. It needed a team to support this newfound demand for piloting the technology and that is where ConsenSys Social Impact came out.</p>
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={6}>
                                <Image src = "https://storage.googleapis.com/vv-images/emergingimpact_image1.jpeg" className="blogpost-img"/>
                                <p className="img-caption">Work in the Philippines providing drinkable water while working for Consensys Social Impact.</p>
                            </Col>
                        </Row>
                        <p> BSIC became its own foundation that continues the same community building efforts whereas ConsenSys Social Impact was an organization within ConsenSys that handpicked business engagement from major NGOs to educate them, develop software proof-of-concepts, and make case studies out of the results. Over the course of that period, we worked on 15–17 proof-of-concepts; developed 13 partnerships including the United Nations, Red Cross, and World Bank; and worked in 13 different countries. We were finding some service market fit and in some cases product-market fit as some projects continue to be their own products like <a className="blog-link" href="https://impactio.global/" target="_blank" rel="noopener noreferrer">Impactio</a>, a transparent grant mechanism created with the World Wildlife Fund.</p>
                        <p>However, social impact for most commercial businesses is still supplemental. Even though we were generating revenue and creating those products, the larger focus for ConsenSys as a firm shifted to decentralized finance (DeFi). We decided to externalize ConsenSys Social Impact as a separate entity. We still had clients, and we knew we were nowhere near realizing the true potential of the technology when it comes to pilots in the social sector.</p>
                    </div>
                    <div className="interview-question">
                        <p><b className="interview-question-q">What challenges are there for companies which focus on both social impact and profit?</b></p>
                        <p>There’s a two-prong issue where social enterprises have difficulty finding enough balance between people and profit so that they can get operational funding. They may be comfortable with the amount of money that they’re making, but [the challenge] is making enough to actually get an investment, get a loan, or actually scale. In this age of increased conscious capitalism, commercial businesses are starting to realize they need to make social impact less supplemental. They are struggling to do so, because they have investors that are pressuring these companies to make profit by cutting employees or outsourcing production to countries with forced labor.</p>
                        <p>I think moving forward there’s going to be a very heavy reckoning for those types of businesses. The young generations are coming into a much different world. They are seeing people get shot by police, California on fire, and Covid-19 happening. It’s immediately apparent to them that whatever they are going to do in life, it’s going to be helping solve these problems in contrast to “oh I just want to graduate college and I get a good job.”</p>
                        <Image src = "https://storage.googleapis.com/vv-images/emergingimpact_image2.jpeg" className="blogpost-img"/>
                        <p className="img-caption">Inspiring the young generation at Devcon Scholar Program, a scholarship that provides Ethereum members from underserved communities, unique circumstances, or developing areas an opportunity to attend the largest gathering in the Ethereum ecosystem.</p>
                        <p>I think part of the challenge is in the legal structures that social enterprises have to choose from. It’s hard to know that you might not make as great margins because you have NGOs as clients, and you may not get the same tax consideration of a non-profit. But [for a non-profit], there is no equity in it. That is why we chose to be a Public Benefit Corporation where the social impact is part of our reporting. While we don’t have any investors now, [with this legal structure], investors would know the context that they are getting into.</p>
                    </div>
                    <div className="interview-question">
                        <p><b className="interview-question-q">What challenges have you experienced while growing the company?</b></p>
                        <p>After officially leaving ConsenSys to pursue this, my mind was saying “either this is going to work or it’s not and I’m going to be homeless.” I’ve lived in my car before, so I’m comfortable taking those risks.</p>
                        <p>By finding early service market fit in the first quarter, we had made a quarter million dollars. But expanding operational capacity within a service-based business is difficult because projects are mapped to humans, not products. It requires hiring more people and figuring out how to give everyone a good wage.</p>
                        <p>Also being a founder of color, and I’ve noticed this for women founders as well, we don’t have the same opportunity like our white male colleagues to present an idea pre-revenue and pre-product and raise funding for millions of dollars. It has been frustrating trying to figure out how to expand the operational funding to catch up with the needs of this growing list of customers that want to create impact.</p>
                        <p>In terms of hiring people, we focus a lot on diversity. Sixty percent of our team identifies as female, and we have 78 percent ethnic diversity. I’ve seen the benefit of having that level of diversity. There are things that I or my other male co-founders are not great at that our female co-founder is amazing at. If your vantage points as a team were to make a circle, without certain backgrounds, then that circle is not complete. Regardless of what school someone attended, that perspective is going to make your team more performant. It’s going to bring something to the conversation that you would not have even considered.</p>
                    </div>
                    <h4 className="blog-quote">“The young generations are coming into a much different world. They are seeing people get shot by police, California on fire, and Covid-19 happening. It’s immediately apparent to them that whatever they are going to do in life, it’s going to be helping solve these problems.”</h4>
                    <div className="interview-question">
                        <p><b className="interview-question-q">What’s your take on the current state of diversity in the blockchain/crypto space?</b></p>
                        <p>I see [diversity] play out in the technical assumptions that we make when developing the products that we hope to be adopted by the emerging-market consumers. The first assumption is just language. The Ethereum Foundation recently realized if everything is provided in English, then you cannot allow people to innovate in their own countries. The second assumption is of different types of literacy including reading and writing literacy, as well as technical literacy. Every product should have a version that can be used by someone who is illiterate if that company hopes for adoption in the under-resourced communities. The last assumption is low internet connectivity.</p>
                        <p>If a company has the opportunity, I would advise them to have a potential consumer or user on their team or as an advisor who understands that [user’s] perspective. I think that is not often sought out because it may damage our understanding of what the solution should be. Or more often, companies are just not thinking about it.</p>
                    </div>
                    <h4 className="blog-quote">“If your vantage points as a team were to make a circle, without certain backgrounds, then that circle is not complete.”</h4>
                    <div className="interview-question">
                        <p><b className="interview-question-q">What was your childhood like and how does it contribute to your work?</b></p>
                        <p>I was born in California but raised in Atlanta. Coming from the deep South, I was called the n-word plenty of times like during soccer games, completely out of context. It always comes when you least expect it. My dad listened to cassette tapes of Martin Luther King, Malcolm X, Wu-Tang Clan, and Public Enemy. So I became very aware of who I was and who I was not, along with all the emotional struggles in terms of identity.</p>
                        <p>I found blockchain around 2011, graduated from University of Michigan for undergrad and Emery for my MBA. I worked at a lot of different places that eventually brought me to founding Emerging Impact. But it’s that origin story of racial awareness that activated a lot of my passions now.</p>
                    </div>
                    <div className="interview-question">
                        <p><b className="interview-question-q">Can you share a little about your campaign #CryptoForBlackLives and what motivated it?</b></p>
                        <Row>
                            <Col xs={12} sm={12} md={6} lg={6}>
                                <Image src = "https://storage.googleapis.com/vv-images/emergingimpact_image3.jpeg"className="blogpost-img"/>
                                <p className="img-caption">Posing for a New York Times OpEd on #BBUM</p>
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={6}>
                                <p>My activist roots started during my University of Michigan days where I participated in <a className="blog-link" href="https://www.huffpost.com/entry/bbum-university-of-michigan-black-students_n_4310790" target="_blank" rel="noopener noreferrer">#BBUM</a>, which was a precursor to the Black Lives Matter Campaign. This was around the time where Trayvon Martin was killed. Then from a professional standpoint every so often, I have feel this urge that my professional work is detracting from empowering the black community, that my time is split between the two. I have gone through some experiences within the crypto space that had a lot of racial bias within them. And something like that puts one in a situation where if you want to be professional you cannot say much, or you could be perceived like an angry black guy.</p>    
                            </Col>
                        </Row>
                        <p>With the crypto community, the original ethos is in trying to decentralize things and make them more fair. We see these messages at every conference, but there is a lack of parity with that and everyone being silent when we see constant videos of black people getting shot. The thing that really got me is that we have all these people that can read and write these complex white papers, but they can’t understand basic history. That angered me so I put out a challenge to the communities that said “if you all really care about this, here are some specific actions you can take.” That has since evolved into a campaign that’s raised $100,000 for multiple organizations such as Black Girls Code, ACLU, and bail funds.</p>
                        <p>The main call to action for organizations is to first release your diversity numbers. There should be no crypto company that believes in transparency that does not release their diversity numbers. If you have bad diversity numbers, that doesn’t mean you are a bad company. The second call is to release a strategy to attend to it.</p>
                    </div>
                    <h4 className="blog-quote">“There should be no crypto company that believes in transparency that does not release their diversity numbers.”</h4>
                    <div className="interview-question">
                        <p><b className="interview-question-q">How can people best support Emerging Impact and the #CryptoForBlackLives campaign?</b></p>
                        <p>People can best support our mission by</p>
                        <ul>1. Helping tell our story of empowering NGOs and social enterprises</ul>
                        <ul>2. Join the campaign</ul>
                        <ul>3. Connect us to potential clients and partners!</ul>
                    </div>
                    <div className="blogpost-video-container">
                        <iframe className="blogpost-video" src="https://www.youtube.com/embed/3nC2Y2ieFCM" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    </div>
                </div>
            </div>
        </div>
    );
}
