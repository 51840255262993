import React from "react";
import Image from 'react-bootstrap/Image';
import { Helmet } from "react-helmet";
import linkedIn_upload from '../../tmpImages/linkedIn_upload.jpg';
import li_upload_step_1 from '../../tmpImages/li_upload_step_1.png';
import li_upload_step_2 from '../../tmpImages/li_upload_step_2.png';
import li_upload_step_3 from '../../tmpImages/li_upload_step_3.png';
import upload_linkedin_vire from '../../tmpImages/upload_linkedin_vire.png';
import upload_linkedin_summary_vire from '../../tmpImages/upload_linkedin_summary_vire.png';
import upload_li_summary_vire from '../../tmpImages/upload_li_summary_vire.png';
import upload_li_enrich_data from '../../tmpImages/upload_li_enrich_data.png'
import job_history_carousel from '../../tmpImages/job_history_carousel.mp4'
import { isMobile } from 'react-device-detect';

import amy_photo from "../../tmpImages/amy_photo.jpeg";


export function LinkedInUpload() {
    return (
        <div className="whitedrop-page">
            <Helmet>
                <title>Vire: LinkedIn Profile Upload</title>
            </Helmet>
            <meta property="og:image" content={ linkedIn_upload } />
            <meta property="og:image:width" content="180" />
            <meta property="og:image:height" content="110" />
            <div>
                <div className="blogpost-container">
                    <div className="blogpost-header-container">
                        <h2 className="blogpost-header">How to Upload your LI Profile to Vire</h2>
                        <div className="author-header">
                            <div>
                                <Image className="author-avatar" src={ amy_photo }/>
                            </div>
                            <div className="blogpost-date-author">
                                <p className="blogpost-date-author-item">Amy Slawson</p>
                                <p className="blogpost-date-author-item">Jul 10, 2024 · 3 min read</p>
                            </div>
                        </div>
                    </div>
                    <Image src={ linkedIn_upload } className="blogpost-header-img"/>
                    <div className="interview-question">
                        <p>Your LinkedIn profile is a good starting point in demonstrating your work history.</p>
                        <p>To reduce duplicate effort, you can upload your LinkedIn profile data to your Vire profile and then further enrich the data by manually responding to the prompts.</p>
                    </div>
                    <div className="interview-question">
                        <h3>How to Upload your LinkedIn Profile to Vire</h3>
                        <h4>1. Request a copy of your data from LinkedIn.</h4>
                        <p>Clicking on <em>“Me”</em> {">"} <em>“Settings & Privacy”</em>.</p>
                        <p>Select <em>“Data Privacy”</em> from the left side panel.</p>
                        <p>Click on <em>“Get a copy of your data”</em>. </p>
                        <Image className="blogpost-img" src={ li_upload_step_1 }/>
                        <p className="img-caption">LinkedIn profile settings.</p>   
                        <p>Opt for <em>“Download larger data archive”</em> and click the <em>“Request archive”</em> button.</p>
                        <p>LinkedIn says it may take up to 24 hours, but the first installment typically is generated in 10-15 minutes. This first version will contain everything you need.</p>
                        <Image className="blogpost-img" src={ li_upload_step_2 }/>
                        <p className="img-caption">Exporting LinkedIn data archive.</p>   
                        <p>When the first installment is ready, you will receive an email. </p>
                        <p>Follow that and select “Download archive” to download the .zip file.</p>
                        <Image className="blogpost-img" src={ li_upload_step_3 }/>
                        <p className="img-caption">Once ready, the page will allow you to download the archive as a .zip file.</p>   
                    </div>
                    <div className="interview-question">
                        <h4>2. Upload your LinkedIn data to Vire.</h4>
                        <p>Access your Vire profile by selecting <em>“Profile”</em> on the left panel.</p>
                        <p>Navigate to the <em>“Profile Upload”</em> section. </p>
                        <p>Note, this will only appear if you have not started the “Expertise” or “Education” sections. This is to avoid overwritting. If you do want to overwrite the existing data, first delete all the entries in these sections. Then you will see the “Profile Upload” option appear.</p>
                        <p>Click <em>“Upload LinkedIn Profile”</em> and select your downloaded .zip file.</p>
                        <Image className="blogpost-img" src={ upload_linkedin_vire }/>
                        <p className="img-caption">Vire upload LinkedIn Profile data.</p>   
                        <p>This will populate your job history and education based on your LinkedIn data.</p>
                        <p>Note, each entry will appear incomplete, and you may not have any summary data yet. This is because the data will need to be enriched by fields not included in your LinkedIn profile. These specific details will help you best showcase your expertise and optimize your match opportunities.</p>
                        <Image className="blogpost-img" src={ upload_linkedin_summary_vire }/>
                        <p className="img-caption">Populated job history from LinkedIn data. Entries ready for further data enrichment.</p>   
                    </div>
                    <div className="interview-question">
                        <h4>3. Enrich your data.</h4>
                        <p>This is where the magic is created 🪄.</p>
                        <p>To make the most of your Vire profile, click the edit icon on each a job experience and follow the prompts through the carousel.</p>
                        <video autoPlay muted loop playsInline={isMobile} controls={isMobile} id="job-history-video" className="blogpost-custom-video" poster={upload_li_enrich_data}>
                            <source src={ job_history_carousel } type="video/mp4"/>
                        </video>
                        <p className="img-caption">Manually edit data in job experience, providing fine details surrounding your expertise like business model, end users, or technologies.</p>
                        <p>As you provide more details you'll see your summary and job history come to life.</p>
                        <Image className="blogpost-img" src={ upload_li_summary_vire }/>
                        <p className="img-caption">Summary and job entries populate and provide an easy way for hiring managers to find critical information.</p>   
                    </div>
                    <div className="interview-question">
                        <h4>4. Don't forget about other profile sections.</h4>
                        <p>Other criteria are just as important for finding the best job matches and cannot be extracted from your LinkedIn profile. That's why we are building a new platform that takes this extra data into consideration.</p>
                        <p>Make sure to populate your <em>“Areas of Interest”</em>, <em>“Optimal Work Environment”</em>, <em>“Top Traits”</em>, <em>“Team Values”</em> and more.</p>
                        <p>And if something matters to you that is not captured by your Vire profile, let us know! We love getting direct feedback about what is important to professionals opening themselves up for the right opportunities. Reach us at <a className="purple-link" href="mailto:hello@joinvire.com">hello@joinvire.com</a>.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}
