import React from "react";
import { Helmet } from "react-helmet";
import Container from 'react-bootstrap/Container';

const PrivacyPolicyPage = () => {
    return (
        <div className="whitedrop-page">
            <Helmet>
                <title>Vire: Privacy Policy</title>
            </Helmet>
            <Container fluid>
                <div className="blogpost-container">
                    <h1>Privacy Policy</h1>
                    <p>Effective Date: Last Updated: August 08, 2024</p>
                    <p>We are Vire Labs, Inc., doing business as Vire ("Company," "we," "us," "our"). We are passionate about using informed data to match candidates and employers with quality opportunities. With that, we are also passionate about protecting your personal data.  This Privacy Policy explains how we collect, use, disclose, and safeguard your information.</p>
                    <p>This Privacy Policy applies to the personal data provided to us while you enjoy the Vire mobile application (“App”), our websites (such as joinvire.com) or using our digital products and services (collectively, referred to in this Privacy Policy as our “Sites”)</p>
                    <p>This Privacy Policy does not apply to information that you may provide to one of our employer partners ("Employers"). You may be redirected to apply directly with Employers and provide information via their recruitment services. We will use the information in accordance with our agreement with that Employer. Your agreement with that Employer should explain how they share your information with us. Please contact the Employer if you have questions about this sharing. We are not responsible for the privacy practices of any third-party website operator that you may link to from our website.</p>
                    <h2>Information We Collect</h2>
                    <p><b>1. Collection of personal data.</b></p>
                    <p>We may collect personal information that you voluntarily provide to us when registering on the Sites, expressing an interest in obtaining information about us or our products and services, when participating in activities on the Sites, or otherwise contacting us.</p>
                    <p>The personal information we collect may include:</p>
                    <ul>
                        <li>Personal Data: Full name, email address, phone number, photographs, and other contact information.</li>
                        <li>Credentials: Passwords, password hints, and similar security information used for authentication and account access.</li>
                        <li>Employment Details: Resume, employment history, educational background, skills, and references.</li>
                    </ul>
                    <p><b>2. Information we collect automatically on our Sites.</b></p>
                    <p>We may use cookies and similar technologies when you visit our Sites to help customize and improve your experience. A cookie is a small file that can be placed on your device or browser that allows us to recognise and remember you. These technologies may record information of your use of our Sites, including:</p>
                    <ul>
                        <li>Browser and device data, such as IP address, location, device type, operating system and Internet browser type, screen resolution, operating system name and version, device manufacturer and model, language, plug-ins, add-ons and the language version of the Sites you are visiting.</li>
                        <li>Usage data, such as time spent on the websites, pages visited, links clicked, searches performed on our Sites, and the pages that led or referred you to our websites.</li>
                    </ul>
                    <p>Log data is collected for the purpose of analysis, management, security, and audit of the information technology systems. We use this information to monitor and analyze use of the Sites, for the Sites' technical administration, to increase our Sites' functionality and user-friendliness, and to verify users have the authorization needed for the Sites to process their requests.</p>
                    <p>The security of your log data is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your log data, we cannot guarantee its absolute security.</p>
                    <p><b>3. Collection of sensitive personal information.</b></p>
                    <p>You may choose to enhance your candidate profile with information that could be categorized as "sensitive" such as self identifying race or gender. Providing this information is optional, but it can help you get more from our services, such as Employers using this data to find you. It can also help us identify and react to bias behavior on the platform. If you do choose to share this data, please be mindful that it becomes a part of your profile.</p>
                    <p><b>4. Information Provided to us by Employers</b></p>
                    <p>We may contract with Employers who use the Company services. These employers may share Personal Data about individuals who have applied for employment opportunities with their organization (an "Applicant"). This Privacy Policy does not apply to Applicants. Please review and refer to the terms of service and privacy policy of the that organization to understand your rights. Should you, as an Applicant, choose to create an account with us, it may include personal information previously provide by these Employers.</p>
                    <h2>Use of Your Information</h2>
                    <p>Having accurate information about you permits us to provide you with a smooth, efficient, and customized experience. Specifically, we may use information collected about you via the Sites to:</p>
                    <ul>
                        <li>Create and manage your account.</li>
                        <li>Personalize the App/Sites and the content we deliver to you.</li>
                        <li>Contact you with information about the App (e.g., updates and new features).</li>
                        <li>Conduct research and analytics about how you use and interact with the App/Sites.</li>
                        <li>Test new technologies and processes designed to enhance and improve the App/Sites.</li>
                        <li>To comply with a regulatory or other legal request.</li>
                    </ul>
                    <p><b>Our Matching Algorithms</b></p>
                    <p>Vire's mission is to optimize candidate-role fit with criteria important to both parties, you and Employers. We have developed matching algorithms to predict your compatibility, using indicators based on your profile. Our matching algorithms are based on a range of factors and use machine learning to rank opportunities for you to maximize your chance of finding compatible matches. We use the following data about you and other users to predict your compatibility with opportunities and generate recommendations: <b>the information you provide us about yourself in your profile</b> such as expertise, motivations, and optimal work environment so we can show you opportunities where you are a good fit; <b>information about how you interact with our app</b> to suggest to you Employers who are active and to further understand your preferences. Where we process information about your app activity, this is based on our legitimate interests pursuant to Article 6(1)(f) GDPR, and specifically our legitimate interest in generating more personalized and relevant recommendations for employment opportunities. Where you have included sensitive information in your profile, such as outlined in "Collection of sensitive personal information," the processing of this information is based on your voluntary and explicit consent.</p>
                    <p><b>Disclosure of Information</b></p>
                    <p>We may share information we have collected about you in certain situations. Your information may be disclosed as follows:</p>
                    <ul>
                        <li>By Law or to Protect Rights</li>
                        <li>Third-Party Service Providers</li>
                        <li>Marketing Communications</li>
                        <li>Interactions with Other Users</li>
                        <li>Online Postings</li>
                        <li>Public Profiles</li>
                        <li>Security of Your Information</li>
                        <li>Business Partners</li>
                    </ul>
                    <p>We do not share, rent or sell your personal data for interest-based advertising.</p>
                    <p>We may share generic aggregated demographic information not linked to any personal identification information regarding visitors and users with our business partners, trusted affiliates, and advertisers. We may use third-party service providers to help us operate our business and the Sites or administer activities on our behalf, such as sending out newsletters or surveys.</p>
                    <p>We use administrative, technical, and physical security measures to help protect your personal information. While we have taken reasonable steps to secure the personal information you provide to us, please be aware that despite our efforts, no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed against any interception or other type of misuse.</p>
                    <h2>Data Retention and Deletion</h2>
                    <p>We only keep your personal information for as long as it is necessary for the purposes set out in this Privacy Policy, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements).</p>
                    <h2>Your Rights</h2>
                    <p>We are relying on your consent to process your personal information, which may be expressed and/or implied by your use of the Sites. You have the right to withdraw your consent at any time. If you withdraw your consent, we may not be able to provide you with access to the certain specific functionalities of the Sites.</p>
                    <p>If you are a resident of the European Economic Area (EEA), you have certain data protection rights. If you wish to be informed what personal data we hold about you and if you want it to be removed from our systems, please contact us.</p>
                    <h2>Changes to This Policy</h2>
                    <p>As Vire continues to evolve, we may revise this Privacy Policy from time to time. Our Sites and services are dynamic, and we often add new features, which may require collecting new information. If we make a change that, in our sole discretion, materially changes the personal data or how we collect, use or share your data, we will notify you and may also modify this Privacy Policy and update the "Effective Date". We may also provide you with disclosures and alerts regarding the Privacy Policy or Personal Data collected by posting them on our website and, if you have an account, by contacting you through your dashboard and/or email address.</p>
                    <h2>Contact Us</h2>
                    <p>If you have questions or comments about this Privacy Policy, please contact us at:</p>
                    <p>Vire Labs, Inc.</p>
                    <p>hello@joinvire.com</p>
                </div>
            </Container>
        </div>
    );
}

export default PrivacyPolicyPage;