import React from "react";
import { Helmet } from "react-helmet";
import Container from 'react-bootstrap/Container';

const TermsAndConditions = () => {
    return (
        <div className="whitedrop-page">
            <Helmet>
                <title>Vire: Terms and Conditions</title>
            </Helmet>
            <Container fluid>
                <div className="blogpost-container">
                    <h1>Terms and Conditions</h1>
                    <p>Last Updated: April 04, 2024</p>
                    <p>We are Vire Labs, Inc., doing business as Vire ("Company," "we," "us," "our").</p>
                    <p>You can contact us by email at hello@joinvire.com.</p>
                    <p>These legal terms constitute a legally binding agreement made between you, whether personally or on behalf of an entity ("you"), and Vire Labs, Inc., concerning your access to and use of the Services. You agree that by accessing the Services, you have read, understood, and agreed to be bound by all of these legal terms. IF YOU DO NOT AGREE WITH ALL OF THESE LEGAL TERMS, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE SERVICES AND YOU MUST DISCONTINUE USE IMMEDIATELY.</p>
                    <h2>1. Use of the Platform</h2>
                    <p>a. Eligibility: You must be at least 18 years old to use the Platform. By agreeing to these Terms, you represent and warrant that you are at least 18 years old.</p>
                    <p>b. Account Registration: To access certain features of the Platform, you may be required to create an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.</p>
                    <p>c. User Responsibilities: You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account. You agree to immediately notify us of any unauthorized use of your account.</p>
                    <h2>2. Content</h2>
                    <p>a. User-Generated Content: Users may post, upload, publish, submit, or transmit content, including but not limited to resumes, job postings, and company profiles ("User Content"). By making available any User Content on or through the Platform, you grant us a worldwide, irrevocable, perpetual, non-exclusive, transferable, royalty-free license, with the right to sublicense, to use, view, copy, adapt, modify, distribute, license, sell, transfer, publicly display, publicly perform, transmit, stream, broadcast, access, view, and otherwise exploit such User Content on, through, or by means of the Platform.</p>
                    <p>b. Content Accuracy: We do not endorse any User Content or any opinion, recommendation, or advice expressed therein, and we expressly disclaim any and all liability in connection with User Content.</p>
                    <h2>3. Purchase and Payment</h2>
                    <p>Use of Services is free of charge. However, we may charge fees for certain features or services. If you elect to use paid features or services, you agree to the pricing and payment terms as published on the Services. We may add new services for additional fees and charges, or amend fees and charges for existing services, at any time in our sole discretion.</p>
                    <p>You authorize us to charge all fees as described in these Legal Terms for the Services you select to that payment method. If your payment method fails or your account is past due, we may collect fees using other collection mechanisms. Your account may be deactivated without notice to you if payment is past due, regardless of the dollar amount.</p>
                    <h2>4. User Representation</h2>
                    <p>By using the Services, you represent and warrant that:</p>
                    <ul>
                        <li>all registration information you submit will be true, accurate, current, and complete;</li>
                        <li>you will maintain the accuracy of such information and promptly update such registration information as necessary;</li>
                        <li>you have the legal capacity and you agree to comply with these Legal Terms;</li>
                        <li>you are not under the age of 18;</li>
                        <li>not a minor in the jurisdiction in which you reside, or if a minor, you have received parental permission to use the Services;</li>
                        <li>you will not access the Services through automated or non-human means, whether through a bot, script, or otherwise;</li>
                        <li>you will not use the Services for any illegal or unauthorized purpose; and</li>
                        <li>your use of the Services will not violate any applicable law or regulation.</li>
                    </ul>
                    <p>If you provide any information that is untrue, inaccurate, not current, or incomplete, we have the right to suspend or terminate your account and refuse any and all current or future use of the Services.</p>
                    <h2>5. Privacy</h2>
                    <p>Your privacy is important to us. Our Privacy Policy outlines how we collect, use, and protect your information. By using the Platform, you agree to the collection and use of information in accordance with our Privacy Policy.</p>
                    <h2>6. Prohibited Activities</h2>
                    <p>You may not access or use the Services for any purpose other than that for which we make the Services available. The Services may not be used in connection with any commercial endeavors except those that are specifically endorsed or approved by us.</p>
                    <p>As a user of the Services, you agree not to:</p>
                    <ul>
                        <li>systematically retrieve data or other content from the Services to create or compile, directly or indirectly, a collection, compilation, database, or directory without written permission from us;</li>
                        <li>make any unauthorized use of the Services, including collecting usernames and/or email addresses of users by electronic or other means for the purpose of sending unsolicited email, or creating user accounts by automated means or under false pretenses;</li>
                        <li>use the Services to advertise or offer to sell goods and services;</li>
                        <li>circumvent, disable, or otherwise interfere with security-related features of the Services, including features that prevent or restrict the use or copying of any Content or enforce limitations on the use of the Services and/or the Content contained therein;</li>
                        <li>engage in unauthorized framing of or linking to the Services;</li>
                        <li>trick, defraud, or mislead us and other users, especially in any attempt to learn sensitive account information such as user passwords;</li>
                        <li>make improper use of our support services or submit false reports of abuse or misconduct;</li>
                        <li>use the Service in a manner inconsistent with any applicable laws or regulations</li>
                        <li>attempt to impersonate another user or person or use the username of another user;</li>
                        <li>sell or otherwise transfer your profile;</li>
                        <li>use any information obtained from the Services in order to harass, abuse, or harm another person;</li>
                        <li>copy or adapt the Services' software, including but not limited to Flash, PHP, HTML, JavaScript, or other code;</li>
                        <li>upload or transmit (or attempt to upload or to transmit) viruses, Trojan horses, or other material, including excessive use of capital letters and spamming (continuous posting of repetitive text), that interferes with any party's uninterrupted use and enjoyment of the Services or modifies, impairs, disrupts, alters, or interferes with the use, features, functions, operation, or maintenance of the Services;</li>
                        <li>upload or transmit (or attempt to upload or to transmit) any material that acts as a passive or active information collection or transmission mechanism, including without limitation, clear graphics interchange formats ("gifs"), 1x1 pixels, web bugs, cookies, or other similar devices (sometimes referred to as "spyware" or "passive collection mechanisms" or "pcms");</li>
                        <li>except as may be the result of standard search engine or Internet browser usage, use, launch, develop, or distribute any automated system, including without limitation, any spider, robot, cheat utility, scraper, or offline reader that accesses the Services, or using or launching any unauthorized script or other software;</li>
                    </ul>
                    <h2>7. Disclaimers & Limitation of Liability</h2>
                    <p>The Platform is provided on an "AS IS" and "AS AVAILABLE" basis. Your use of the Platform is at your sole risk. We disclaim all warranties, express or implied, of any kind related to the Platform and its use. In no event shall [Recruitment Platform Name], nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from your access to or use of or inability to access or use the Platform.</p>
                    <h2>8. Governing Law</h2>
                    <p>These Terms shall be governed by and construed in accordance with the laws of [Jurisdiction], without regard to its conflict of law provisions.</p>
                    <h2>9. Changes to Terms</h2>
                    <p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. We will provide notice of any changes by posting the new Terms on the Platform.</p>
                    <h2>10. User Data</h2>
                    <p>We will maintain certain data that you transmit to the Platform for the purpose of managing the performance of the Platform, as well as data relating to your use of the Platform. Although we perform regular routine backups of data, you are solely responsible for all data that you transmit or that relates to any activity you have undertaken using the Platform.</p>
                    <h2>11. Electronic Communications & Transactions</h2>
                    <p>By using the Platform, you consent to receive electronic communications from us. These communications may include notices about your account and information concerning or related to the Platform. You agree that any notices, agreements, disclosures, or other communications that we send to you electronically will satisfy any legal communication requirements, including that such communications be in writing.</p>
                    <h2>12. California Users & Residents</h2>
                    <p>If any complaint with us is not satisfactorily resolved, you can contact the Complaint Assistance Unit of the Division of Consumer Services of the California Department of Consumer Affairs in writing at 1625 North Market Blvd., Suite N 112, Sacramento, California 95834 or by telephone at (800) 952-5210.</p>
                    <h2>10. Contact Us</h2>
                    <p>If you have any questions about these Terms, please contact us at:</p>
                    <p>Vire Labs, Inc.</p>
                    <p>hello@joinvire.com</p>
                </div>
            </Container>
        </div>
    );
};

export default TermsAndConditions;