import React from 'react';
// import { TwitterLogo, MediumLogo, InstagramLogo, LinkedInLogo } from './SocialLogos'



export function Footer() {
    return <div>
        <div className="vire-footer">
            <hr></hr>
            <div className="social-logos-footer">
                {/* <MediumLogo/> */}
                {/* <InstagramLogo/> */}
                {/* <LinkedInLogo/> */}
                {/* <TwitterLogo/> */}
            </div>
            <div className="footer-links">
                <p><a className="generic-link" href="/blog">Blog</a>    |   <a className="generic-link" href="/employers">Employers</a>    |   <a className="generic-link" href="/privacy-policy">Privacy Policy</a>    |   <a className="generic-link" href="/terms-and-conditions">Terms and Conditions</a></p>
            </div>
        </div>
    </div>
}