//https://medium.com/@dmccoy/how-to-submit-an-html-form-to-google-sheets-without-google-forms-b833952cc175
export const SIGNUP_TYPES = {VIRE_WAITLIST : "vire-waitlist", VIRE_NEWSLETTER : "vire-newsletter"};

export function sendEmail(email, signuptype, buttonId, completeMessage) {
    let signupbutton =  document.getElementById(buttonId);
    signupbutton.disabled = true;
    signupbutton.textContent = "Hold tight"
    let url = "https://script.google.com/macros/s/AKfycbwynAVz9VAmNh5ocb5vft1MU4NkZbnM5MwLWOT8-vNsE5Zv10qndP6FScZK-9q5lNHh/exec?" + {signuptype}.signuptype + "=" + {email}.email
    return fetch(url, {
        "method": "GET",
        dataType: "json"
    })
        .then(() => {
            document.getElementById({signuptype}.signuptype).style.display = 'none'
            document.getElementById('signup-btn-div').style.display = 'none'
            document.getElementById({completeMessage}.completeMessage).style.display = 'inline'
        })
        .catch(err => {
            signupbutton.disabled = false
            console.log(err);
        });
}

export function sendEmployerEmail(email, company, atsLink, message, buttonId, successMessage, errorMessage) {
    let signupbutton =  document.getElementById(buttonId);
    signupbutton.disabled = true;
    document.getElementById({successMessage}.successMessage).style.display = 'none'
    document.getElementById({errorMessage}.errorMessage).style.display = 'none'

    signupbutton.textContent = "Hold tight"
    let url = "https://script.google.com/macros/s/AKfycbyKA4sjyAFi7umytT1lM9sCddHxocaVTd8nxdTuocdMUQoLwFlwvpGFkYi8zAvbM8W5Cg/exec"
    return fetch(url, {
        "method": "POST",
        body: JSON.stringify({
            email: email,
            company: company,
            atsLink: atsLink,
            message: message
        }),
        dataType: "json"
    })
        .then(() => {
            signupbutton.textContent = "Sent"
            document.getElementById({successMessage}.successMessage).style.display = 'block'
        })
        .catch(err => {
            signupbutton.textContent = "Submit"
            document.getElementById({errorMessage}.errorMessage).style.display = 'block'
            signupbutton.disabled = false
            console.log(err);
        });
}