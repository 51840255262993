import React, { useState, useEffect } from 'react';

const RotatingHeaderText = () => {
  const words = ["growth opportunity", "mission impact", "company trajectory", "diverse leadership", "solving hard problems", "cutting-edge technology", "work style alignment", "developing skills", "culture", "solving important problems", "team vibe", "supportive benefits", "influence"];
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % words.length);
    }, 2000); // Change the word every 2 seconds

    return () => {
      clearInterval(interval);
    };
  }, [words.length]);

  return <span>{words[index]}</span>;
};

export default RotatingHeaderText;